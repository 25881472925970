$primary-color: #1d1d27;
$secondary-color: #eb3437;
$secondary-color-not-selected: #e7676a;
$grey-background: #656565;
$text-color: #cbd5db;

:export {
  primaryColor: $primary-color;
  secondaryColor: $secondary-color;
  secondaryColorNotSelected: $secondary-color-not-selected;
  greyBackground: $grey-background;
  textColor: $text-color;
}