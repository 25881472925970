@import "../../../colors";
@import "../../../sizes";

//.navbar {
//  position: fixed;
//  top: 0;
//  z-index: 10;
//  width: 100%;
//  height: 70px;
//  display: flex;
//  justify-content: space-between;
//  background: $secondary-color;
//  color: $text-color;
//  align-items: center;
//}

.navbar {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: space-between;
  background: $secondary-color;
  color: $text-color;
  align-items: center;
  padding: 5px;
}

.nav-link {
  text-decoration: none;
  font-weight: bold;
  color: $text-color;
  margin: 13px 10px 13px 10px;
  transition: 0.5s;
}

.nav-link:hover {
  text-shadow: 0 0 50px $primary-color;
  color: white;
}

.menu {
  display: flex;
  align-items: center;
}

.menu li {
  padding: 20px;
}

.mobile-menu-button {
  display: none;
}

@media screen and (max-width: $media1){

  .mobile-menu-button {
    display: block;
    padding-right: 10px;
    padding-left: 5px;
    z-index: 100;
    cursor: pointer;
  }

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    left: -200%;
    right: 0;
    bottom: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    background: $primary-color;
    transition: left .5s;
  }

  .active {
    left: 0;
  }
}

