@import "../../colors";

.App {
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $secondary-color;
  border: 1px solid $primary-color;
}

::spelling-error {
  color: greenyellow;
}

.App-contents {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 110px;
}