@import "../../../colors";
@import "../../../sizes";

$borderRadius: 5px;

//.new-contents {
//    display: flex;
//    /*width: 100%;*/
//    box-sizing: border-box;
//    justify-content: space-between;
//
//}

.new-content {
    flex: 1;
    overflow: hidden;
    transition: .5s;
    line-height: 0;
    /*font-size: 1.3vw;*/
    text-align: center;
    margin: 10px;
    box-shadow: 20px 20px 30px rgba(0, 0, 0, .5);
    color: $text-color;
    background-color: $grey-background;
    border-color: $grey-background;
    position: relative;
    cursor: pointer;
    border-style: solid;
    border-radius: $borderRadius;
}

.contents {
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    overflow: auto;
    //scrollbar-width: none;
}

//.contents::-webkit-scrollbar {
//    display: none;
//}

.content {
    width: 210px;
    height: 430px;
    text-decoration: none;
    text-align: center;
    margin: auto;
    transition: .5s;
    //box-shadow: 20px 20px 30px rgba(0, 0, 0, .5);
    color: $text-color;
    background-color: $primary-color;
    border-radius: $borderRadius;
    border: 1px solid $primary-color;
}

.content:hover {

}

.description {
    //font-weight: bold;
    //margin-top: -3px;
    width: 80%;
    background: $grey-background;
    border: 3px solid $grey-background;
    border-top: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 3px;
}

.description div {
    padding: 3px;
}

.contents-slider {
    cursor: grab;
    height: 360px;
}

.contents-slider:active {
    cursor: grabbing;
}

@media screen and (max-width: 630px){
    .content {
        width: 120px;
        height: 320px;
    }

    .contents-slider {
        height: 270px;
    }
}



