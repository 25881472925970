@import "../../../colors";
$borderRadius: 5px;


.link-to-download-page {
  text-decoration: none;
  color: $text-color;
}

.new-content-poster {
  width: 100%;
  height: 100%;
  border-radius: $borderRadius;
  transition: .5s;
  background: $grey-background;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-content-poster img {
  width: 100%;
  height: 100%;
  border-radius: $borderRadius;
  margin-bottom: 0;
  outline: 3px solid $primary-color;
  transition: .5s;
}

.new-content-poster img:hover {
  outline: 3px solid $grey-background;
}

.image {
  width: 100%;
  height: 70%;
  border-radius: $borderRadius;
  transition: .5s;
  background: $grey-background;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image img {
  width: 100%;
  height: 100%;
  border-radius: $borderRadius;
  margin-bottom: 0;
  outline: 3px solid $primary-color;
  transition: .5s;
}

.image img:hover {
  outline: 3px solid $grey-background;
}

