@import "../../../../colors";

.search-input {
  //margin: 10px;
  font-weight: bold;
  //font-size: 19px;
  font-size: inherit;
  color: white;
  width: 100%;
  padding: 11px;
  background-color: transparent;
  background: url(search_icon.png) no-repeat scroll;
  background-size: 35px 35px;
  background-position: 98% center;
  border-radius: 50px;
  border: 1px solid $text-color;
  transition: all 250ms ease-in-out;
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.search-input::placeholder {
  color: #c3c8cb;
  //letter-spacing: 1.5px;
}

.search-input:hover,
.search-input:focus {
  padding: 12px 0;
  outline: 0;
  border: transparent;
  border-bottom: 1px solid $text-color;
  border-radius: 0;
  background-position: 100% center;
}

.search-div {
  position: fixed;
  width: 540px;
  max-height: 400px;
  background: $primary-color;
  border: 2px solid $grey-background;
  border-top: 0;
  padding: 10px;
  top: 70px;
  z-index: 100;
  overflow: auto;
}

