@import "../../../../colors";

.accordion {
  min-width: 550px;
}

summary {
  font-weight: bold;
  background: $grey-background;
  list-style: none;
  cursor: pointer;
  position: relative;
  padding: 20px;
  margin: 5px 0 5px 0;
  border-radius: 3px;
  transition: margin .2s ease-out;
}

summary:before {
  font-weight: bold;
  font-size: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  content: "◄";
  width: 50px;
  height: 50px;
  color: $text-color;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

summary:hover {
  background-color: $secondary-color;
  color: white;
  transition: .5s;
}

details[open] summary {
  margin-bottom: 20px;
}

details[open] summary:before {
  content: "▼";
}

.accordion-contents {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.episode-link {
  text-decoration: none;
  cursor: pointer;
  background: $secondary-color-not-selected;
  color: $text-color;
  font-weight: bold;
  padding: 10px;
  border: none;
  border-radius: 3px;
  margin: 0 5px 0 5px;
  transition: .5s;
  //box-shadow: 0 0 10px $primary-color;
}

.episode-link:hover {
  color: white;
  background: $secondary-color;
  //padding: 10px;
}