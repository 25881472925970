@import "../../../../colors";

.link {
  display: flex;
  cursor: default;
  font-weight: bold;
  //justify-content: space-between;
  background: $grey-background;
  border-radius: 3px;
  margin-bottom: 5px;
  padding: 10px;
}

.link a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-weight: bold;
  color: $text-color;
  float: right;
}

.link p {
  margin: auto auto auto 0;
}

.link button {
  font-size: inherit;
  cursor: pointer;
  background: $primary-color;
  color: $text-color;
  font-weight: bold;
  padding: 8px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 10px $primary-color;
  transition: .5s;
}

.link button:hover {
  background: $secondary-color;
  color: white;
}