@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import "../../../../colors";
@import "../../../../sizes";


.new-contents {
  text-align: center;
  width: 100%;
  margin: auto;
  //height: 570px;
}

.new-content {
  width: 280px;
  height: 450px;
  align-items: center;
  justify-content: center;
  justify-items: center;
  margin: auto;
}

.slide{
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
  justify-content: center;
  align-items: center;
}

.slide-content{
  //width: 200px;
  //height: 300px;
}

.activeSlide{
  transform: scale(1);
  opacity: 1;
}

.arrow{
  color: $secondary-color;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  z-index: 10;
}

.arrow svg{
  transition: color 300ms;
}

.arrow svg:hover {

}

.next {
  right: 0%;
  top: 50%;
}

.prev {
  left: 0%;
  top: 50%;
}

@media screen and (max-width: $media1){
  .new-content {
    //width: 120px;
    //height: 200px;
  }
}

@media screen and (max-width: $media2){
  .new-content {
    width: 120px;
    height: 200px;
  }
}
