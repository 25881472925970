@import "colors";
@import "sizes";

body {
  background-color: $primary-color;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: $font-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text-color;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h2 {
  font-size: 1.5rem;
}

ul {
  list-style-type: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

@media screen and (max-width: $media1){
  body {
    font-size: $media1-font-size;
  }
}

@media screen and (max-width: $media2){
  body {
    font-size: $media2-font-size;
  }
}

