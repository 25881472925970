@import "../../../colors";
@import "../../../sizes";

.main-div {
  margin-top: 100px;
}

.info-div {
  display: flex;
}

.description-div {
  margin-left: 40px;
}

.poster {
  border-style: solid;
  border-radius: 10px;
  background: $grey-background;
  border-color: $secondary-color;
  box-shadow: -20px 20px 50px rgba(0, 0, 0, .7);
  width: 400px;
  height: 588px;
  flex-shrink: 0;
}

.poster img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.description-title {
  color: $secondary-color;
  text-align: justify;
  font-weight: bold;
}

.logo {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.qualities {
  margin-top: 10px;
  display: flex
}

.qualities label{
  cursor: pointer;
  font-weight: bold;
  padding: 10px;
  background: $grey-background;
  margin-right: 5px;
  border-radius: 3px;
}

.qualities label:hover {
  background: #e7676a;
  transition: .5s;
}

.qualities input[type="radio"] {
  display: none;
}

.qualities input[type="radio"]:checked+label {
  color: white;
  background: $secondary-color;
}

.links {
  margin-top: 30px;
  text-decoration: none;
}

.seasonSelect {
  background: $grey-background;
  color: $text-color;
  font-weight: bold;
  font-size: inherit;
  border-radius: 3px;
  border: none;
  padding: 8px;
  cursor: pointer;
}

@media screen and (max-width: $media1){

  .poster {
    width: 330px;
    height: 485px;
  }

  .info-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description-div {
    margin-left: 0;
  }
}

@media screen and (max-width: $media2){

  .poster {
    width: 290px;
    height: 426px;
  }

  .info-div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .description-div {
    margin-left: 0;
  }
}







