$font-size: 18px;
$media1: 850px;
$media2: 480px;
$media1-font-size: 17px;
$media2-font-size: 15px;

:export {
  font-size: $font-size;
  media1: $media1;
  media2: $media2;
  media1-font-size: $media1-font-size;
  media2-font-size: $media2-font-size;
}